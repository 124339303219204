import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import IssuesHeader from "../../components/issues-header"
import IssueCards from "../../components/issue-cards"

import brookeKaufman from '../../images/endorsements/brooke-kaufman.webp'
import cindyEngelhardt from '../../images/endorsements/cindy-engelhardt.webp'
import climateActionRhodeIsland from '../../images/endorsements/climate-action-rhode-island-logo.webp'
import dianaAfonso from '../../images/endorsements/diana-afonso.webp'
import gregMundy from '../../images/endorsements/greg-mundy.webp'
import henryPreston from '../../images/endorsements/henry-preston.webp'
import housingOpportunitiesForPeopleEverywhereLogo from '../../images/endorsements/housing-opportunities-for-people-everywhere-logo.webp'
import jacobFrey from '../../images/endorsements/jacob-frey.webp'
import janetIsserlis from '../../images/endorsements/janet-isserlis.webp'
import jeffNoble from '../../images/endorsements/jeff-noble.webp'
import johnnyBrito from '../../images/endorsements/johnny-brito.webp'
import kendraAnderson from '../../images/endorsements/kendra-anderson.webp'
import nancySafran from '../../images/endorsements/nancy-safran.webp'
import ninaMarcov from '../../images/endorsements/nina-marcov.webp'
import peterHurley from '../../images/endorsements/peter-hurley.webp'
import providenceFireFightersLogo from '../../images/endorsements/providence-fire-fighters-logo.webp'
import providenceSunriseMovementLogo from '../../images/endorsements/providence-sunrise-movement-logo.webp'
import providenceTeachersUnionLogo from '../../images/endorsements/providence-teachers-union-logo.webp'
import ruthBreindel from '../../images/endorsements/ruth-breindel.webp'
import sandyOliveria from '../../images/endorsements/sandy-oliveria.webp'
import steveFletcher from '../../images/endorsements/steve-fletcher.webp'
import substanceUsePolicyEducationRecoveryPac from '../../images/endorsements/substance-use-policy-education-recovery-pac-logo.webp'
import tiaraMack from '../../images/endorsements/tiara-mack.webp'
import youngDemocratsRhodeIslandLogo from '../../images/endorsements/young-democrats-rhode-island-logo.webp'
import yvonneSmart from '../../images/endorsements/yvonne-smart.webp'

const endorsements = [
  {
    "image": `${dianaAfonso}`,
    "quote": "Thank you for your continued strong effort to keep the community a great place to live. My family and I truly appreciate all your hard work!",
    "name": "Diana Afonso",
    "title": "Ward 1 Resident, Providence"
  },
  {
    "image": `${ruthBreindel}`,
    "quote": "John Goncalves cares about Providence.  For years, he has been organizing groups to talk about taxes and to bring the various neighborhood associations together into an effective body.  He has been working to help the seniors in his district by collecting fabric masks and getting them to congregate settings; I know this, because I have made masks for him.  In every way, he is the epitome of what the city of Providence needs.",
    "name": "Ruth Breindel",
    "title": "Ward 1 Resident, Providence"
  },
  {
    "image": `${cindyEngelhardt}`,
    "quote": "He's a progressive with fresh ideas.",
    "name": "Cindy Engelhardt",
    "title": "Ward 1 Resident, Providence"
  },
  {
    "image": `${peterHurley}`,
    "quote": "Hard working!",
    "name": "Peter Hurley",
    "title": "Ward 1 Resident, Providence"
  },
  {
    "image": `${janetIsserlis}`,
    "quote": "He has integrity, he listens. He does his homework, he cares about the community and its wellbeing.",
    "name": "Janet Isserlis",
    "title": "Ward 1 Resident, Providence"
  },
  {
    "image": `${brookeKaufman}`,
    "quote": "Honest, conscientious, smart and good-hearted.",
    "name": "Brooke Kaufman",
    "title": "Ward 1 Resident, Providence"
  },
  {
    "image": `${gregMundy}`,
    "quote": "I support John because he is aware of the challenges we face in Fox Point, India Point Park and Providence in general.",
    "name": "Greg Mundy",
    "title": "Ward 1 Resident, Providence"
  },
  {
    "image": `${jeffNoble}`,
    "quote": "Great person, hard-worker, has the common person's interests at heart.",
    "name": "Jeff Noble",
    "title": "Ward 1 Resident, Providence"
  },
  {
    "image": `${henryPreston}`,
    "quote": "Cares deeply about our city, respects and protects the historic resources of our city and is super responsive to the residents of his district.",
    "name": "Henry Preston",
    "title": "Ward 1 Resident, Providence"
  },
  {
    "image": `${nancySafran}`,
    "quote": "He is a dedicated and tireless advocate for our neighborhood , community and the city.",
    "name": "Nancy Safran",
    "title": "Ward 1 Resident, Providence"
  },
  {
    "image": ``,
    "quote": "His communication and work while he has served as a council member has been nothing short of wonderful.",
    "name": "Jessica Giorgi",
    "title": "Ward 1 Resident, Providence"
  },
  {
    "image": `${yvonneSmart}`,
    "quote": "I have known John since he was a child coming daily to the Fox Point Library. He is an intelligent, caring man whose innate curiosity has always led him to go beyond the easy solutions, the fast answer. He will serve the people well because I know he works hard to analyze each situation and not accept the quick fix.",
    "name": "Yvonne Smart",
    "title": "Ward 1 Resident, Providence"
  },
  {
    "image": `${providenceTeachersUnionLogo}`,
    "quote": "The Providence Teachers Union is proud to endorse John Goncalves as a member of the Providence City Council - Ward 1. John is an educator, alumnus of Providence Public Schools, and an advocate for our students. Moreover, he understands the importance of investing in our City's educators, students and school infrastructure.  Our organization endorses John Goncalves as both a political candidate and community leader. He has demonstrated his capacity of organization and community outreach by being a co-founding member of the Providence Coalition of Neighborhood Associations; a collective network of neighborhood leaders that work to collaboratively address shared issues. Coupled with his commitment to equity, John's ability to bring people together will benefit the residents of Ward 1 and the City of Providence. We look forward to working with John in order to improve working conditions for educators and learning conditions for our students.  Who you vote for is your choice. However, the PTU encourages you to support Goncalves as a member of the Providence City Council.",
    "name": "The Providence Teachers Union, Local 958",
    "title": ""
  },
  {
    "image": `${providenceFireFightersLogo}`,
    "quote": "We are pleased to inform the public that the Providence Fire Fighters IAFF Local 799 is proud to enthusiastically endorse John Goncalves for Providence City Council in Ward 1.\nThe Providence Fire Fighters IAFF believes in electing and reelecting candidates who demonstrate an understanding of the challenges and needs facing firefighters and those who also recognize the important work that our profession does for the public safety in the Providence community.\nJohn has been a Ward 1 and Providence resident for over 25 years and an important advocate for bringing the city together as one of the founders of the Providence Coalition of Neighborhood Associations, an organization that brought together 19 neighborhood associations across the city to tackle city-wide issues. His policy experience. including roles as Chair of the Minnesota Senate District 60 Committee, Administrative Director,  Strategy Director, Policy Aide/Advisor in key city council, mayoral, attorney general, and Senate races, will make him an asset on the Council. As a Brown University alum, native Providence resident, and staunch community and public safety advocate, John is the perfect voice to represent Ward 1 which encompasses Fox Point as well as the East Side neighborhoods of College Hill, Wayland Square. the Jewelry District and Downtown Providence.\nWe look forward to working with John. Congratulations on receiving our support!",
    "name": "Providence Fire Fighters IAFF, Local 799",
    "title": ""
  },
  {
    "image": `${youngDemocratsRhodeIslandLogo}`,
    "quote": "The Young Democrats of Rhode Island (YDRI) has voted to endorse John Goncalves for Providence City Council Ward 1. John has been a life long member of the Fox Point community, he is currently an educator at The Wheeler School and graduated from Brown University with both his undergraduate and Masters. John has been an exemplary member of his community serving as a board member of the Fox Point Neighborhood Association. John was also involved in the founding of the Providence Coalition of Neighborhood Associations that has included representation from over 19 neighborhood associations across the city. As the Young Democrats of Rhode Island, our mission is to empower the young people of our state to become involved and John Goncalves is a wonderful example of that! Goncalves has spent much of his time listening to the people of Ward 1 and it has showed through his advocacy for small businesses and his work on Providence property taxes. Goncalves has spoken on issues of environmental justice,  public education, and affordable housing, he is truly a voice for not only his community but also for the young Democrats he is going to represent! The Young Democrats of Rhode Island looks forward to working to see Goncalves to victory on the June 2nd General Election.",
    "name": "Young Democrats of Rhode Island",
    "title": ""
  },
  {
    "image": `${providenceSunriseMovementLogo}`,
    "quote": "On the day originally scheduled for the Providence Ward 1 general election, we want to congratulate John Goncalves as the presumed incoming City Councilmember. We look forward to working with John, and the rest of the City Council, to create and implement a Green New Deal for our city. The Green New Deal was a key element of John's platform, and in talking to residents he stressed his commitment to halting all new fossil fuel projects and fighting against environmental injustice and racism in Providence. We especially appreciate John's vision--informed by his experience as an educator--to work with universities, community colleges, and trade schools in Providence to develop training programs for the thousands of good, clean jobs the Green New Deal will create, and to ensure that fossil fuel industry workers are the first to receive this retraining and job placement. As the COVID-19 crisis makes abundantly clear, our economy and society is deeply unequal and unjust. The Green New Deal is exactly the kind of recovery we need, and we are eager to fight alongside John to make it a reality.",
    "name": "Sunrise Movement (Statement of Support, Not Official Endorsement)",
    "title": ""
  },
  {
    "image": `${substanceUsePolicyEducationRecoveryPac}`,
    "quote": "We are delighted to endorse John Goncalves for Providence City Council Ward 1, who is running to represent Ward 1 (Fox Point/Downtown) in the Providence City Council during a special election. John is running to ensure that each and every resident of Ward 1 is supported: he deeply understands the importance of looking at issues, including substance use, holistically. John is dedicated to ensuring that we treat substance use as a public health issue, providing services, evidence-based practices, and compassion to people who use substances. John also understands that the criminalization of substance use has not worked and has led to inequitable outcomes: he is committed to looking at the root causes of these topics and ensuring that policies are both equitably created and implemented. We look forward to working together to better support people who use substances and those in recovery in Ward 1, as well as in Providence more broadly. We know there is a lot going on in the world right now, and -- with that-- we are further committed to supporting elected officials and leaders who are working to uphold the health and safety of Rhode Islanders.",
    "name": "Substance Use Policy, Education, & Recovery PAC",
    "title": ""
  },
  {
    "image": `${climateActionRhodeIsland}`,
    "quote": "Of the three candidates, John Goncalves demonstrated by far the deepest commitment to CARI's mission. His vision of how to effect Providence's transition from fossil fuels to renewable energy exhibits a strong understanding of the challenges and opportunities for action at the city level. He speaks knowingly and relevantly about general and local environmental justice and racism issues, informed by his work organizing neighborhood associations city-wide to oppose polluting facilities in the Port of Providence. He didn't just voice support for, but actually signed onto, the ECRI Climate Crisis Campaign Statement, the Green New Deal Pledge, and the No Fossil Fuel Money Pledge. We believe John Goncalves will be a tireless advocate for the renewable energy transition our city and world so desperately need. This Tuesday, March 3, we urge all residents of Ward 1 (Fox Point/Jewelry District) to cast a vote for John Goncalves.",
    "name": "Climate Action Rhode Island",
    "title": ""
  },
  {
    "image": `${housingOpportunitiesForPeopleEverywhereLogo}`,
    "quote": "HOPE applauds John's centering of affordable housing issues in his campaign. In representing one of the city's more affluent wards, John knows the importance of curbing gentrification and ensuring that long-term residents can stay in their homes. His leadership in re-evaluating the city's taxation policies gives him important experience in advocating for elderly and low- to middle-income residents for whom increased tax assessments often place an undue burden. No resident should be priced out of the neighborhoods they've called home for decades, and John will fight to prevent this from happening.",
    "name": "Housing Opportunities for People Everywhere (HOPE)",
    "title": ""
  },
  {
    "image": `${jacobFrey}`,
    "quote": "John Goncalves has the experience and intellect to be a real leader who will champion a forward-thinking vision. As the Mayor of Minneapolis, I've had the great honor of working with John and I'm 100% confident in his ability to drive meaningful change for the benefit of the community.",
    "name": "Jacob Frey",
    "title": "Mayor of Minneapolis, MN"
  },
  {
    "image": `${steveFletcher}`,
    "quote": "John Goncalves served as my Strategy Director during his time living in the Twin Cities. There is no question that he deeply understands policy and has the legislative wherewithal to serve his constituents and effectively get the job done as a member of the Providence City Council.",
    "name": "Steve Fletcher",
    "title": "City Councilman, Ward 3, Minneapolis, MN"
  },
  {
    "image": `${sandyOliveria}`,
    "quote": "John Goncalves has been a part of my life since he was 5 years old. I have always found him to be honest, dedicated, and hardworking. He truly cares about the community he grew up in. I can't think of a better person to help Ward 1 become a stronger and a more vibrant community.",
    "name": "Sandy Oliveria",
    "title": "Retired Librarian, Fox Point Public Librarian"
  },
  {
    "image": `${johnnyBrito}`,
    "quote": "John Goncalves is a homegrown product of the community. His ties to and his investment in the people of Fox Point and the East Side are undeniable. As a lifelong resident of Fox Point, I wholeheartedly support his candidacy.",
    "name": "Johnny Britto",
    "title": "Former Athletic Director of Boys & Girls Club of Providence, Fox Point Resident"
  },
  {
    "image": `${ninaMarcov}`,
    "quote": "I want to make a plug for Ward 1 City Council candidate John Goncalves.  John -- who was born and raised in Fox Point, attended Brown as an undergraduate and for graduate school, and is now a Wheeler School faculty member -- has proven himself to be extremely effective in a very short time; he organized the Providence Coalition of Neighborhood Associations; helped turn out a big crowd to oppose the two-tiered residential tax proposal last spring; and is in favor of regulating student housing developers (unlike some of the other candidates, who represent special interests). For John, residents and the community come first.",
    "name": "Nina Marcov",
    "title": "Member of College Hill Neighborhood Association"
  },
  {
    "image": `${kendraAnderson}`,
    "quote": "I was brought up in Fox Point and the neighborhood has always been important to me.  I pay attention to what is happening there, and as an environmental activist who is also running for office, I am excited to see that Providence Ward 1 Candidater John Calves is committed to tackling environmental racism and creating a Green New Deal in Rhode Island.  When elected, I know he will work tirelessly for environmental and social justice, and that is why I am proud to endorse him in this race!",
    "name": "Kendra Anderson",
    "title": "Senate District 31 Candidate"
  },
  {
    "image": `${tiaraMack}`,
    "quote": "Whether it's his work as an organizer and founder of the Providence Coalition of Neighborhood Associations, his work on numerous non-profit boards, or his willingness to do research on issues before coming to conclusions -- there are so many reasons to support John.\nThere is one particular reason above all that makes John the right choice for the job.\nJohn is the only candidate to name environmental racism as the main reason Providence has one of the highest asthma rates in the country.  We need leaders in Providence that understand all the root causes that lead to economic, education, and environmental inequity so we can work towards building everyone in our communities up.",
    "name": "Tiara Mack",
    "title": "Providence Senate District 6 Candidate"
  },
]

const EndorsementsPage = () => (
  <Layout>
    <Seo title="Endorsements" />
    <div className="bg-white">
        <div className="max-w-7xl mx-auto py-10 px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <p className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
              Endorsements
            </p>
          </div>
        </div>

        <div className="relative bg-gray-100 pb-10 px-4 sm:px-6 lg:pb-10 lg:px-8">  
          <div className="relative max-w-7xl mx-auto pt-10 pb-10">
            <div className="max-w-lg mx-auto grid gap-5 lg:grid-cols-4 lg:max-w-none">
              {endorsements.map((endorsement) => (
                <div key={endorsement.title} className="flex flex-col shadow-lg overflow-hidden">
                  <div className="flex-shrink-0">
                    <img className="w-full object-cover" src={endorsement.image} alt="" />
                  </div>
                  <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                    <div className="flex-1">
                      <div className="block">
                        <p className="text-xl font-semibold text-gray-900">{endorsement.name}</p>
                        <p className="text-sm font-medium text-orange-headline">
                          {endorsement.title}
                        </p>
                        <p className="mt-3 text-base text-gray-500">{endorsement.quote}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        
        {/*{endorsements.map((endorsement) => (
          <div className="relative max-w-7xl mx-auto pt-20 pb-12 px-4 sm:px-6 lg:px-8 lg:py-20">
            <svg
              className="absolute top-full left-0 transform translate-x-80 -translate-y-24 lg:hidden"
              width={784}
              height={404}
              fill="none"
              viewBox="0 0 784 404"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="e56e3f81-d9c1-4b83-a3ba-0d0ac8c32f32"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={784} height={404} fill="url(#e56e3f81-d9c1-4b83-a3ba-0d0ac8c32f32)" />
            </svg>

            <div className="relative lg:flex lg:items-center">
              <div className="hidden lg:block lg:flex-shrink-0">
                <img
                  className="h-64 w-64 rounded-full xl:h-80 xl:w-80"
                  src={endorsement.image}
                  alt=""
                />
              </div>

              <div className="relative lg:ml-10">
                <svg
                  className="absolute top-0 left-0 transform -translate-x-8 -translate-y-24 h-36 w-36 text-indigo-200 opacity-50"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 144 144"
                  aria-hidden="true"
                >
                  <path
                    strokeWidth={2}
                    d="M41.485 15C17.753 31.753 1 59.208 1 89.455c0 24.664 14.891 39.09 32.109 39.09 16.287 0 28.386-13.03 28.386-28.387 0-15.356-10.703-26.524-24.663-26.524-2.792 0-6.515.465-7.446.93 2.327-15.821 17.218-34.435 32.11-43.742L41.485 15zm80.04 0c-23.268 16.753-40.02 44.208-40.02 74.455 0 24.664 14.891 39.09 32.109 39.09 15.822 0 28.386-13.03 28.386-28.387 0-15.356-11.168-26.524-25.129-26.524-2.792 0-6.049.465-6.98.93 2.327-15.821 16.753-34.435 31.644-43.742L121.525 15z"
                  />
                </svg>
                <blockquote className="relative">
                  <div className="text-2xl leading-9 font-medium text-gray-900">
                    <p>
                      {endorsement.quote}
                    </p>
                  </div>
                  <footer className="mt-8">
                    <div className="flex">
                      <div className="flex-shrink-0 lg:hidden">
                        <img
                          className="h-12 w-12 rounded-full"
                          src={endorsement.image}
                          alt=""
                        />
                      </div>
                      <div className="ml-4 lg:ml-0">
                        <div className="text-base font-medium text-gray-900">{endorsement.name}</div>
                        <div className="text-base font-medium text-indigo-600">{endorsement.title}</div>
                      </div>
                    </div>
                  </footer>
                </blockquote>
              </div>
            </div>
          </div>
        ))}*/}
    </div>
  </Layout>
)

export default EndorsementsPage
